<template>
  <div>
    <!-- <v-toolbar dense class="elevation-1" color="darkblue">
      <v-icon color="white" class="text-h4">mdi-file-tree</v-icon>
      <v-toolbar-title
        style="letter-spacing: 0.5rem"
        class="ml-3 font-weight-bold text-uppercase white--text"
        >Treinamentos
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar> -->

    <v-row class="pa-6">
      <v-col
        cols="6"
        sm="4"
        md="3"
        lg="2"
        v-for="folder in folders"
        :key="folder.id"
        class="py-4"
      >
        <div class="container" @click="toFiles(folder.id)">
          <div class="folder_tab"></div>
          <div class="folder d-flex align-center justify-center">
            <div>
              <div
                class="font-weight-bold"
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'font-size: 1.1rem'
                    : 'font-size: 1rem'
                "
              >
                {{ folder.name }}
              </div>
              <div class="font-weight-medium" style="font-size: 0.9rem">
                {{ folder.trainingFiles.length }} {{ $t('homework.homework_arquivo.arquivo2') }}
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

export default {
  data: () => ({
    folders: [],
    course: [],
    idFolders: [],
  }),
  async created() {
    //this.getFolders();
    await this.getTeam()
  },
  methods: {
    getTeam() {
      axios
        .get(`${url}/coursesForTeacher`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          let Teams = res.data;
          console.log(Teams)
          this.getCorserFolder(Teams)

        })
        .catch((error) => {
          console.error(error);
        });
    },

    async getCorserFolder(Teams){
      let z = 0
      for (let x = 0; x < Teams.length; x++) {
        let res = await axios.get(
         `${url}/folderAssemblies/testando/${Teams[x].id}`,
          authorization,
        );
        this.course = res.data
        //console.log(this.course)
        for (let y = 0; y < this.course.length; y++) {
          let folder = await axios.get(
            `${url}/trainingFolders/${this.course[y].fk_folder}`,
          authorization,
          );
          this.idFolders[z] = folder.data[0]
          z += 1
        }
      }
      this.folders = this.idFolders
      console.log(this.folders)
    },
    // async getFolders() {
    //   let res = await axios.get(`${url}/trainingFolders`, authorization);
    //   this.folders = res.data;
    // },
    toFiles(id) {
      this.$parent.$parent.$parent.trainingId = id;
      localStorage.setItem("id", id);
      this.$router.push("/teacher/Training/Files")
      this.$parent.$parent.$parent.page = this.$t('homework.homework_tab.tab2');
    },
  },
};
</script>

<style lang="css" scoped>
.container {
  padding: 5px;
  text-align: center;
}
.folder_tab,
.folder {
  margin: 0 auto;
  background-color: #ffe189;
  cursor: pointer;
}
.folder_tab {
  width: 40%;
  height: 15px;
  margin-right: 60%;
  border-radius: 10px 20px 0 0;
}
.folder {
  height: 150px;
  border-radius: 0 10px 10px 10px;
  box-shadow: 3px 6px 5px 0px #cccccc;
}
</style>
